import axios from "axios";
const ENDPOINT = "/api/v1/sms";
const MAIL = "/api/v1/send-email";

const get = async payload => {
  return axios.post(ENDPOINT, payload);
};

const sendMail = async payload => {
  return axios.post(MAIL, payload);
};

const create = async payload => {
  return await axios.post(ENDPOINT, payload);
};

const update = async payload => {
  return await axios.put(ENDPOINT, payload);
};

const remove = async payload => {
  return axios.delete(ENDPOINT + "/" + payload);
};

export { get, create, update, remove, sendMail };
