<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} Level</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Add Name"
                    v-model="formData.name"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Description"
                    v-model="formData.descriptions"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid"
            :title="title"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
export default {
  props: ['closeDialog', 'dialog', 'isEditing', 'formData', 'save', 'loading'],
  name: 'OptionDialog',
  data: () => ({
    valid: true,
    isValid: {
      name: [
        (v) => !!v || ' Name required',
        /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',
      ],
    },
  }),
  methods: {
    add() {
      let data = { ...this.formData }
      data.code = data.code
        ? data.code
        : data.name.trim().split(' ').join('_').toUpperCase()

      this.save(data)
    },

    close() {
      this.closeDialog()
    },
  },
  computed: {
    title() {
      return this.formData.id ? 'Update' : 'Create'
    },
  },
  mounted() {},
}
</script>
